export enum ETileGridType {
  UNSET = 'UNSET',
  SINGLE = 'SINGLE',
  CUSTOM = 'CUSTOM',
  SINGLE_HIGHLIGHT = 'SINGLE_HIGHLIGHT',
  SHOP = 'SHOP', //4er Grid for productTiles
  SHOP_SINGLE_MOBILE = 'SHOP_SINGLE_MOBILE',
  SHOP_ADVANCED = 'SHOP_ADVANCED', //1er Grid for productTile
  CAMPAIGN_SINGLE_MOBILE = 'CAMPAIGN_SINGLE_MOBILE',
}

export type ITileOptimalHeightEntry = {
  id: number;
  yPos: number;
  contentHeight: number;
};
