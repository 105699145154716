import { ETileSize } from '@/@types/tile-size';
import { ETileGridType } from '~~/src/@types/tile-grid-type';
import { SSR_safe_mq_breakpointIsMobile } from '~~/src/injectionSymbols';
export function useTiles() {
  const isMobile = inject(SSR_safe_mq_breakpointIsMobile);

  // @workaround: https://github.com/nuxt/nuxt/issues/21901
  const tileRefs = reactive<{ overline: HTMLDivElement }[]>([]);
  function updateItemRefs(el: any) {
    tileRefs.push(el);
  }

  onMounted(() => {
    setOverlineHeights(tileRefs);
    if (import.meta.client) {
      useResizeObserver(document.body, () => setOverlineHeights(tileRefs));
    }
  });

  function setOverlineHeights(tiles: { overline: HTMLDivElement }[]) {
    const map = Object.values(
      tiles.reduce(
        (map, tile) => {
          if (tile?.overline) {
            tile.overline.style.height = 'auto';
            const { y, height } = tile.overline.getBoundingClientRect();
            if (!map[y]) map[y] = [];
            map[y].push({
              height,
              el: tile.overline,
            });
          }

          return map;
        },
        {} as Record<number, { height: number; el: HTMLDivElement }[]>,
      ),
    );
    map.forEach((tileRow) => {
      const height = `${Math.max(...tileRow.map((cur) => cur.height))}px`;
      tileRow.forEach(({ el }) => {
        if (!isMobile.value) el.style.height = height;
      });
    });
  }

  function getSizeByGridType(
    gridType: ETileGridType,
    tileIndex: number,
  ): ETileSize {
    switch (gridType) {
      case ETileGridType.CUSTOM:
        if (tileIndex === 0) return ETileSize.LARGE;
        else if (tileIndex === 4) return ETileSize.SLIM;
        return ETileSize.NORMAL;
      case ETileGridType.UNSET:
      case ETileGridType.SINGLE:
        return ETileSize.NORMAL;
      case ETileGridType.SINGLE_HIGHLIGHT:
        return ETileSize.SLIM;
      case ETileGridType.SHOP: //4er GRid
        return ETileSize.SHOP;
      case ETileGridType.SHOP_SINGLE_MOBILE:
        return ETileSize.SHOP_SINGLE_MOBILE;
      case ETileGridType.SHOP_ADVANCED: //1er Grid
        return ETileSize.SHOP_ADVANCED;
      case ETileGridType.CAMPAIGN_SINGLE_MOBILE:
        return ETileSize.CAMPAIGN_SINGLE_MOBILE;
      default:
        return ETileSize.NORMAL;
    }
  }

  return {
    updateItemRefs,
    getSizeByGridType,
  };
}
